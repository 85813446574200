import Vue from 'vue' //引入Vue构造器
import VueRouter from 'vue-router'  //引入路由构造器
import login from './login'
import home from './home'

Vue.use(VueRouter)  //注册路由插件

// 创建路由配置项
const routes = [
  // 登录页
  {
    name: 'Login',
    path: '/login',
    component: () => import('../views/Login.vue'),
    redirect: { name: 'CaLogin' },
    children: [...login]
  },
  // 首页
  {
    name: 'Home',
    path: '/home',
    component: () => import('../views/Home.vue'),
    redirect: { name: '首页' },
    children: [...home]
  },
  // 测试页面
  {
    name: 'Test',
    path: '/test',
    component: () => import('../views/Test.vue'),
  },


  // * 页面
  {
    // name: '404',
    path: '*',
    redirect: '/home'
  },
]

// 解决重复点击相同地址，elementUI报警告的问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

// 创建路由实例并传入路由配置项
const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log('scrollBehaviorscrollBehaviorscrollBehavior', to, from, savedPosition);
    if (savedPosition) {
      return {
        ...savedPosition,
        y: savedPosition.y-112
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 暴露传入了路由配置项的路由实例
export default router
