import Vue from 'vue' //引入Vue构造器
import Vuex from 'vuex' //引入Vuex构造器

Vue.use(Vuex) //注册Vuex

//判断是生产环境(production)还是开发环境(development)
let online = process.env.NODE_ENV === 'production'

// 生产环境访问地址（线上）
let onlineUrl = {
  baseURL: `https://dzztb-api.ygzdztb.com/mh`  //门户网站
}

// 开发环境访问地址（本地）
let devUrl = {
  // baseURL: 'http://192.168.3.38:51111' //门户网站（本地）
  // baseURL: 'http://39.99.44.45:51011' //门户网站（测试环境）
  baseURL: `https://test-dzztb-api.ygzdztb.com/mh`  //门户网站
}

// 暴露new出来的仓库实例
export default new Vuex.Store({
  // 状态
  state: {
    // 请求后台代码的地址
    baseURL: online ? onlineUrl.baseURL : devUrl.baseURL, //门户网站

    // 访问前台代码的地址(用于跳转到投标人端)
    bidderURL: online ? 'https://tbr.ygzdztb.com/#/home/index/notice' :
      'https://test-tbr.ygzdztb.com/#/home/index/notice',  //投标人端

    // 导航navList
    nav: [],

  },
  // 改变状态
  mutations: {
    // 获取导航（把导航通过params传进来赋值给state.nav）
    getNav(state, params) {
      state.nav = params
    },

  },
  // 计算属性
  getters: {

  },
  // 异步改变数据
  actions: {

  },
  // 拆分仓库
  modules: {

  }
})
