<template>
  <div class="bread-crumb">
    <!-- 面包屑导航 -->
    <aside class="nopad">
      <span class="icon">
        <img src="@/assets/images/new-ui/ic_dw.svg" />
      </span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(v,i) in breadData" :key="i" :to="v.to">{{v.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </aside>
  </div>
</template>

<script>
export default {
  created() {
    this.updateBreadData();
  },
  data() {
    return {
      breadData: []
    };
  },
  watch: {
    "$route.path"(newVal, oldVal) {
      this.updateBreadData();
    }
  },
  methods: {
    // 更新breadData，页面刷新组件刚加载时调用，地址栏路由发生变化时调用
    updateBreadData() {
      this.breadData = [
        { to: { name: "首页" }, title: "首页" },
        ...this.$route.meta.bread //获取当前路由中的meta中的bread
      ];
    }
  }
};
</script>

<style lang="less" scoped>
.bread-crumb {
  background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
  box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
  border: 1px solid #FFFFFF;
  height: 60px;
  font-size: 14px;
  color: #666;
  /deep/ .el-breadcrumb__separator {
    vertical-align: bottom;
  }
  // 面包屑导航
  aside {
    height: 60px;
    line-height: 40px;
    width: 1200px;
    margin: 0 auto;
    padding-left: 246px;
    display: flex;
    align-items: center;
    &.nopad {
      padding-left: 0;
    }
    .icon {
      font-size: 14px;
      color: rgba(137, 138, 141, 1);
      img {
        width: 24px;
        margin-right: 2px;
      }
    }
    
  }
}
</style>