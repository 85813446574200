import Vue from 'vue' //引入Vue构造器
import App from './App.vue' //引入根组件
import router from './router' //引入暴露出来的路由实例（里面有路由配置）
import store from './store' //引入暴露出来的仓库实例（里面有仓库配置）

Vue.config.productionTip = false  //阻止生产提示

// 引入reset.css和common.css
import './assets/css/reset.css'

// 引入iconfont.css
import './assets/fonts/iconfont/iconfont.css'

// 引入并注册ElementUI
import ElementUI, { Breadcrumb } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './assets/css/common.css'

// 全局引入moment
import moment from 'moment'
moment.locale('zh-cn');
window.moment = moment
Vue.prototype.moment = moment;

// 在Vue的原型链上加一个$bus
Vue.prototype.$bus = new Vue()

// 全局注册面包屑组件
import BreadCrumb from './components/Layout/BreadCrumb.vue'
Vue.component('BreadCrumb', BreadCrumb)

// 创建Vue实例
new Vue({
  router, //注入路由实例
  store,  //注入仓库实例
  render: h => h(App) //渲染根组件
}).$mount('#app') //挂载容器#app
