export default [
  // ca登录
  {
    name: 'CaLogin',
    path: 'ca-login',
    component: () => import('../views/Login/CaLogin.vue')
  },
  // 普通登录
  {
    name: 'GeneralLogin',
    path: 'general-login',
    component: () => import('../views/Login/GeneralLogin.vue')
  },
]