export default [
  // 招采信息详情（招标公告、变更公告）
  {
    name: '招采信息详情',
    path: 'tender-notice-detail',
    component: () => import('../views/Home/TradeInfoDetail/TenderNoticeDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '招采信息详情' },
      ]
    }
  },
  {
    name: '快速招采信息详情',
    path: 'tender-fast-detail',
    component: () => import('../views/Home/TradeInfoDetail/FastDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: '3', type: '全部',n: 1 } }, title: '快速招采信息' },
        { to: '', title: '快速招采信息详情' },
      ]
    }
  },
  {
    name: '快速招采公示详情',
    path: 'tender-fast-publicity-detail',
    component: () => import('../views/Home/TradeInfoDetail/FastPublicityDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: '3', type: '全部',n: 1 } }, title: '快速招采公示详情' },
        { to: '', title: '快速招采公示详情' },
      ]
    }
  },
  {
    name: '招采变更信息详情',
    path: 'tender-changenotice-detail',
    component: () => import('../views/Home/TradeInfoDetail/ChangeNoticeDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '招采变更信息详情' },
      ]
    }
  },
  {
    name: '合同公告详情',
    path: 'tender-notice-detail',
    component: () => import('../views/Home/TradeInfoDetail/contractAnnouncementDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '合同公告详情' },
      ]
    }
  },
  // 补遗通知详情
  {
    name: '招采信息补遗通知详情',
    path: 'addendum-inform-detail',
    component: () => import('../views/Home/TradeInfoDetail/AddendumInformDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '招采信息补遗通知详情' },
      ]
    }
  },
  // 招采信息异常公告详情
  {
    name: '招采信息异常公告详情',
    path: 'abnormal-notice-detail',
    component: () => import('../views/Home/TradeInfoDetail/AbnormalNoticeDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '招采信息异常公告详情' },
      ]
    }
  },
  // 招采信息结果公示详情
  {
    name: '招采信息结果公示详情',
    path: 'result-publicity-detail',
    component: () => import('../views/Home/TradeInfoDetail/ResultPublicityDetail.vue'),
    meta: {
      bread: [
        { to: { name: '招采信息', query: { isGov: 1, type: '全部' } }, title: '招采信息' },
        { to: '', title: '招采信息结果公示详情' },
      ]
    }
  },

]