import tradeInfoDetail from './tradeInfoDetail'



export default [
  // 首页
  {
    name: '首页',
    path: 'index',
    component: () => import('../views/Home/Index.vue')
  },

  // 招采信息
  {
    name: '招采信息',
    path: 'trade-info',
    component: () => import('../views/Home/TradeInfo.vue'),
    meta: {
      // bread: [
      //   { to: { name: '招采信息' }, title: '招采信息' }
      // ]
    }
  },
  // 招采信息详情
  ...tradeInfoDetail,

  // 通知公告
  {
    name: '通知公告',
    path: 'inform-notice',
    component: () => import('../views/Home/InformNotice.vue'),
    meta: {
      bread: [
        { to: { name: '通知公告' }, title: '通知公告' }
      ]
    }
  },
  // 通知公告详情
  {
    name: '通知公告详情',
    path: 'notice-detail',
    component: () => import('../views/Home/InformNotice/NoticeDetail.vue'),
    meta: {
      bread: [
        { to: { name: '通知公告' }, title: '通知公告' },
        { to: '', title: '通知公告详情' },
      ]
    }
  },

  // 最新动态
  {
    name: '最新动态',
    path: 'recent-news',
    component: () => import('../views/Home/RecentNews.vue'),
    meta: {
      bread: [
        { to: { name: '最新动态' }, title: '最新动态' },
      ]
    }
  },
  // 最新动态详情
  {
    name: '最新动态详情',
    path: 'news-detailss',
    component: () => import('../views/Home/RecentNews/NewsDetail.vue'),
    meta: {
      bread: [
        { to: { name: '最新动态' }, title: '最新动态' },
        { to: '', title: '最新动态详情' },
      ]
    }
  },

  // 政策法规
  {
    name: '政策法规',
    path: 'policy-regulation',
    component: () => import('../views/Home/PolicyRegulation.vue'),
    meta: {
      bread: [
        { to: { name: '政策法规' }, title: '政策法规' }
      ]
    }
  },
  // 政策法规详情
  {
    name: '政策法规详情',
    path: 'policy-regulation-detail',
    component: () => import('../views/Home/PolicyRegulation/PolicyRegulationDetail.vue'),
    meta: {
      bread: [
        { to: { name: '政策法规' }, title: '政策法规' },
        { to: '', title: '政策法规详情' },
      ]
    }
  },

  // 下载专区
  {
    name: '下载专区',
    path: 'download-section',
    component: () => import('../views/Home/DownloadSection.vue'),
    meta: {
   
    }
  },
  {
    name: '下载专区详情',
    path: 'download-detail',
    component: () => import('../views/Home/NewsInfo/NewsInfoDetail.vue'),
    meta: {
      bread: [
        { to: { name: '下载专区' }, title: '下载专区' },
        { to: '', title: '下载专区详情' },
      ]
    }
  },
  // 下载更多
  {
    name: '下载更多',
    path: 'download-more/:downloadType',
    component: () => import('../views/Home/DownloadSection/DownloadMore.vue'),
    meta: {
      bread: [
        { to: { name: '下载专区' }, title: '下载专区' },
        { to: '', title: '下载更多' },
      ]
    }
  },

  // 服务指南
  {
    name: '服务指南',
    path: 'service-directory',
    component: () => import('../views/Home/ServiceDirectory.vue'),
    meta: {
      bread: [
        { to: '', title: '服务指南' }
      ]
    }
  },
  // 服务指南详情
  {
    name: '服务指南详情',
    path: 'derectory-detail',
    component: () => import('../views/Home/ServiceDirectory/DirectoryDetail.vue'),
    meta: {
      bread: [
        { to: { name: '服务指南', query: { type: 1 } }, title: '服务指南' },
        { to: '', title: '服务指南详情' },
      ]
    }
  },
  // 最新资讯
  {
    name: '最新资讯',
    path: 'news',
    component: () => import('../views/Home/NewsInfo.vue'),
    meta: {
      
    }
  },
  {
    name: '最新资讯详情',
    path: 'news-detail',
    component: () => import('../views/Home/NewsInfo/NewsInfoDetail.vue'),
    meta: {
      bread: [
        { to: { name: '最新资讯' }, title: '最新资讯' },
        { to: '', title: '最新资讯详情' },
      ]
    }
  },
  // 帮助中心
  {
    name: '帮助中心',
    path: 'help',
    component: () => import('../views/Home/Help.vue'),
    meta: {
      
    }
  },
  {
    name: '帮助中心文章详情',
    path: 'help-text-detail',
    component: () => import('../views/Home/NewsInfo/NewsInfoDetail.vue'),
    meta: {
      bread: [
        { to: { name: '帮助中心' }, title: '帮助中心' },
        { to: '', title: '帮助中心文章详情' },
      ]
    }
  },
  // 关于我们
  {
    name: '关于我们',
    path: 'about',
    component: () => import('../views/Home/About.vue'),
    meta: {
      
    }
  },
]